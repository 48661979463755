<template>
	<div class="gonghai">


		<div class="tablebox">

			<div class="addbox" @click="showAdd">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div>
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column type="index" label="序号"></el-table-column>
					<el-table-column prop="permissionDesc" label="权限名称"></el-table-column>
					<!-- <el-table-column prop="departmentDesc" label="部门简介"></el-table-column> -->
					<el-table-column prop="level" label="权限层级"></el-table-column>
					<el-table-column prop="createdTime" label="创建时间"></el-table-column>


					<el-table-column label="操作" width="250">
						<template slot-scope="scope">
							<div class="twobtn">
						

								<div class="kanbox gai" @click="showEdit(scope.row)">
									<img src="@/assets/btnicon/image3.png" />
									改
								</div>

								<div class="kanbox shan" @click="showDelete(scope.row.permissionId)">
									<img src="@/assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<!-- <delete-popup @deleteInfo="_deleteDepartment" ref="deletePopup" message="是否删除部门信息"></delete-popup> -->
		<add-bumeng @updataInfo="_permissionList" ref="addBumeng"></add-bumeng>
		<edit-bumeng @updataInfo="_permissionList" ref="editBumeng"></edit-bumeng>
	</div>
</template>

<script>
	import { permissionList,deletePermission } from "@/network/allback.js"
import deletePopup from "../../../components/common/deletePopup.vue"
import addBumeng from "../popup/addRole.vue"
import editBumeng from "../popup/editRole.vue"
export default {
	data() {
		return {
			totalCount: 0,
			deleteId: '',
			searchInfo: {
				departmentName: '',
				isAsc: 'asc',
				level: '',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				parentDepartmentId: '',
				searchBody: '',
			},
			company: '',
			tableData: []
		};
	},
	components: {
		deletePopup,
		addBumeng,
		editBumeng
	},
	name: 'bumengManage',
	created() {
		this._permissionList()
	},
	methods: {
		// 点击出现删除弹框
		async showDelete(id) {
			
			this.deleteId = id
			const res = await deletePermission({permissionId:id})
			this.$myMessage('删除成功','success')
			this._permissionList()
		},

		// 获取权限列表
		async _permissionList() {
			const res = await permissionList(this.searchInfo)
			console.log("权限列表",res);
			this.tableData = res.data.list
			this.totalCount = res.data.totalCount
		},
		// 点击出现修改弹框
		showEdit(info) {
			this.$refs.editBumeng.drawer = true
			this.$refs.editBumeng.id = info.parentDepartmentId
			
			this.$refs.editBumeng.ruleForm = {
				level: 1,
				parentPermissionId: info.parentPermissionId,
				permissionDesc: info.permissionDesc,
				permissionId: info.permissionId,
				permissionName: info.permissionName,
			}
		},
		
		// 点击出现添加弹框
		showAdd() {
			this.$refs.addBumeng.drawer = true
		},
		handleSizeChange(val) {
			this.searchInfo.pageSize = val
			this._permissionList()
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val
			this._permissionList()
		}
	}
};
</script>

<style scoped lang="scss">

::v-deep {
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #0091ff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 1em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	margin: 0 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
	margin: 0 0 0 1.25em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	background: rgba(50, 134, 255, 0.1);
	// border-radius: 0.25em;
	border: 1px solid #4d91ff;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #3286ff;
}
.tabletop {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
::v-deep {
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		// overflow: hidden;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
		.el-input__inner {
			color: #666666;
			width: 9.75em;
			height: 2.25em;
			line-height: 2.25em;
		}
	}
}

.tablebox {
	background-color: #ffffff;
	padding: 1rem 1.25em;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em 1.25em;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
