// import request from './request'
import axios from 'axios'
import {
	Message,
	MessageBox
} from 'element-ui'
import store from '../store'

//请求地址
/*第一层if判断生产环境和开发环境*/
if (process.env.NODE_ENV == 'production') {
	axios.defaults.baseURL = "http://demo.mojingkj.cn/crm"
} else {
	axios.defaults.baseURL = '/allapi'
}

// axios.defaults.baseURL = 'http://192.168.10.3:3001/crm'
//设置超时时间
axios.defaults.timeout = 5000
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withcredentials = true

//请求拦截(请求发出前处理请求)
axios.interceptors.request.use((config) => {
	const token = localStorage.getItem("adminToken")
	if (token) {
		config.headers['token'] = token
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});

// 响应拦截器（处理响应数据）
axios.interceptors.response.use(
	(res) => {
		const ret = res.data
		// console.log(ret);
		if (ret.status !== 1 && !ret.key && !ret.code) {
			// if(false){
			if (ret.status == 10000) {
				MessageBox.confirm(ret.msg, '系统提示', {
					confirmButtonText: '重新登录',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					store.dispatch('logout')
				})
			} else {
				Message({
					showClose: true,
					message: ret.message || 'Server error',
					type: 'error',
					duration: 2000
				})
			}
			reject(ret.msg)
		}
		return res
	},
	(error) => {
		Message({
			showClose: true,
			message: error.message,
			type: 'error',
			duration: 2000
		})
		return Promise.reject(error);
	}
);

// // 封装get方法
// function get(url, data){
//     console.log(data);
//     return new Promise((resolve, reject) =>{
//         axios.get(url, data).then(res =>{
//             resolve(res.data);
//         }).catch(err =>{
//             reject(err.data);
//         })
//     });
// }


// get请求拼接
function params(obj) {
	let result = '';
	let item;
	for (item in obj) {
		if (obj[item] && String(obj[item])) {
			result += `&${item}=${obj[item]}`;
		}
	}
	if (result) {
		result = '&' + result.slice(1)
	}
	return result
}

// 封装get方法
function get(url, data) {
	let result = '';
	let item;
	for (item in data) {
		if (data[item] && String(data[item])) {
			result += `&${item}=${data[item]}`;
		}
	}
	if (result) {
		result = '?' + result.slice(1)
	}
	url = url + result
	return new Promise((resolve, reject) => {
		axios.get(url).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}



// 封装post方法
function post(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

//对外接口
 function request({
	url,
	method,
	data,
	backend,
}) {
	if (method == 'get') {
		
		return get(url, data);
	} else if (method == 'post') {
		return post(url, data);
	}
}

//权限列表
export function permissionList(params) {
	return request({ url: '/permission/permissionList',method: 'get',data: params})
}
//删除权限
export function deletePermission(params) {
	return request({ url: '/permission/deletePermission',method: 'get',data: params})
}
//添加权限
export function addPermission(params) {
	return request({ url: '/permission/addPermission',method: 'post',data: params})
}
//修改权限
export function editPermission(params) {
	return request({ url: '/permission/editPermission',method: 'post',data: params})
}